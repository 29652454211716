export function saveBrowser(browserName) {
  localStorage.setItem('saypi-browser', browserName)
}

export function deleteBrowser() {
  localStorage.removeItem('saypi-browser')
}

export function hasSavedBrowser() {
  const storedItem = localStorage.getItem('saypi-browser')
  if (storedItem === 'null') {
    // cleanup bad data
    deleteBrowser()
    return false
  }
  return storedItem !== null
}

export function loadBrowser() {
  return localStorage.getItem('saypi-browser')
}
