<template>
  <div class="language-switcher">
    <select
      v-model="currentLocale"
      @change="changeLocale"
      class="locale-select"
      :aria-label="$t('common.selectLanguage')"
    >
      <option v-for="locale in availableLocales" :key="locale.code" :value="locale.code">
        {{ locale.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { setLocale } from '../i18n';

const { locale } = useI18n();
const route = useRoute();
const currentLocale = ref(locale.value);

const availableLocales = [
  { code: 'ar', name: 'العربية' },  // Arabic
  { code: 'zh', name: '中文' },   // Chinese
  { code: 'nl', name: 'Nederlands' },  // Dutch
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },  // French
  { code: 'de', name: 'Deutsch' },  // German
  { code: 'hi', name: 'हिन्दी' },  // Hindi
  { code: 'id', name: 'Bahasa Indonesia' },  // Indonesian
  { code: 'it', name: 'Italiano' },  // Italian
  { code: 'ja', name: '日本語' },  // Japanese
  { code: 'ko', name: '한국어' },  // Korean
  { code: 'ms', name: 'Bahasa Melayu' },  // Malay
  { code: 'fa', name: 'فارسی' },  // Persian
  { code: 'pl', name: 'Polski' },  // Polish
  { code: 'pt-BR', name: 'Português (Brasil)' },  // Portuguese (Brazil)
  { code: 'ru', name: 'Русский' }, // Russian
  { code: 'es', name: 'Español' },  // Spanish
  { code: 'ta', name: 'தமிழ்' },  // Tamil
  { code: 'tr', name: 'Türkçe' },  // Turkish
  { code: 'uk', name: 'Українська' },  // Ukrainian
  { code: 'vi', name: 'Tiếng Việt' }  // Vietnamese
];

const changeLocale = async () => {
  await setLocale(currentLocale.value);
  const currentPath = window.location.pathname;
  const newPath = `/${currentLocale.value}${currentPath.substring(3)}`;
  window.history.pushState({}, '', newPath);
};

// Watch for route changes to update the select
watch(() => route.params.locale, (newLocale) => {
  if (newLocale && currentLocale.value !== newLocale) {
    currentLocale.value = newLocale;
  }
}, { immediate: true });

onMounted(() => {
  // Initialize with the current locale from the route or i18n
  currentLocale.value = route.params.locale || locale.value;
});
</script>

<style scoped>
.language-switcher {
  position: relative;
  display: inline-block;
}

.locale-select {
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
  color: #333;
  font-weight: 500;
}

.locale-select:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.locale-select:focus {
  border-color: rgba(74, 144, 226, 0.6);
  box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
  .locale-select {
    font-size: 2.5rem;
    padding: 1rem 3rem 1rem 1.5rem;
    min-width: 120px;
    background-size: 1.4em;
    background-position: right 1rem center;
    border: 2px solid rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.98);
    font-weight: 500;
  }

  /* Increase touch target size */
  .language-switcher {
    padding: 0.75rem;
    margin: 0.5rem;
  }

  /* Ensure dropdown options are also large and legible */
  .locale-select option {
    font-size: 2.3rem;
    padding: 0.5rem;
  }
}
</style> 