<template>
  <section id="install-guide">
    <h1 class="main-heading">{{ $t('installGuide.title') }}</h1>
    <div class="installation-steps top-level-component text-copy">
      <div v-if="!browserIsSupported && osIsSupported">
        <p>{{ $t('installGuide.browserSupport.notSupported', { browser: browser.name, os: os.name }) }}</p>
        <ul id="supported-browsers" class="supported-items">
          <li
            v-for="supportedBrowser in supportedBrowsers"
            :key="supportedBrowser.name"
            class="browser"
            :class="{
              kiwi: supportedBrowser.name === 'Kiwi',
              lemur: supportedBrowser.name === 'Lemur'
            }"
          >
            <span class="item-name" v-if="supportedBrowser.url">
              <a :href="supportedBrowser.url" target="_blank" :title="$t('installGuide.browserSupport.browserLinks.learnMore')">
                {{ supportedBrowser.name }}
              </a>
            </span>
            <span class="browser item-name" v-else>{{ supportedBrowser.name }}</span>
            <a
              :href="supportedBrowser.appUrl"
              target="_blank"
              class="button install-link"
              :title="$t('installGuide.browserSupport.browserLinks.getButton')"
              v-if="supportedBrowser.appUrl"
            >{{ $t('installGuide.browserSupport.browserLinks.getButton') }}</a>
            <a
              class="button assign-browser kiwi"
              :title="$t('installGuide.browserSupport.browserLinks.assignKiwi')"
              v-if="couldBeKiwi(browser.name, supportedBrowser.name)"
              @click="assignBrowser('Kiwi')"
            >
              {{ $t('installGuide.browserSupport.browserLinks.assignKiwi') }}
            </a>
            <a
              class="button assign-browser kiwi"
              :title="$t('installGuide.browserSupport.browserLinks.assignLemur')"
              v-if="couldBeLemur(browser.name, supportedBrowser.name)"
              @click="assignBrowser('Lemur')"
            >
              {{ $t('installGuide.browserSupport.browserLinks.assignLemur') }}
            </a>
          </li>
        </ul>
        <p
          v-if="browserWasAssigned"
          class="browser"
          :class="{
            kiwi: browser.name === 'Kiwi',
            lemur: browser.name === 'Lemur'
          }"
        >
          <a
            class="button assign-browser"
            :title="$t('installGuide.browserSupport.browserLinks.unassign', { browser: browser.name })"
            @click="unassignBrowser"
          >{{ $t('installGuide.browserSupport.browserLinks.unassign', { browser: browser.name }) }}</a>
        </p>
      </div>
      <div v-else-if="!osIsSupported">
        <p>{{ $t('installGuide.browserSupport.osNotSupported', { os: os.name }) }}</p>
      </div>
      <div v-else>
        <div
          v-for="(step, index) in installationSteps"
          :key="index"
          class="step"
          v-show="index <= currentStep"
        >
          <h2>
            <span class="step-number" :id="'step-' + (index + 1)">{{ $t('installGuide.steps.step', { number: index + 1 }) }}</span>
            {{ step.title }}
            <span v-if="index < currentStep" class="completed-indicator">{{ $t('installGuide.steps.completed') }}</span>
          </h2>
          <div class="step-content">
            <p v-html="step.description"></p>
            <div v-if="step.action" class="action-container">
              <a
                v-if="step.action.type === 'button'"
                :href="step.action.url"
                target="_blank"
                rel="noopener noreferrer"
                class="action-button"
                :class="{ visited: step.action.taken }"
                @click="markStepAsCompleted"
              >
                {{ step.action.text }}
              </a>
              <img
                v-if="step.action.type === 'image'"
                :src="step.action.src"
                :alt="step.action.alt"
                class="action-image"
              />
            </div>
            <p v-if="step.note" class="note">
              <strong>{{ step.note.label }}:</strong> {{ step.note.text }}
            </p>
          </div>
          <div class="navigation-container">
            <button
              v-if="index === currentStep && index < installationSteps.length - 1"
              @click="nextStep"
              class="next-button"
            >
              {{ $t('installGuide.steps.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { 
  getSupportedNativeExtensions, 
  getSupportedBrowsers, 
  getBrowserNotes,
  getLocalizedStoreUrl 
} from './installer.js'
import { saveBrowser, deleteBrowser, hasSavedBrowser, loadBrowser } from './browser-persist.js'
import { UAParser } from 'ua-parser-js'

const { t, locale } = useI18n()
const currentStep = ref(0)
const { os, browser } = UAParser()

const supportedNativeExtensions = ref([])
const supportedBrowsers = ref([])
const agentNotes = ref([])

const browserWasAssigned = computed(() => {
  return hasSavedBrowser()
})

const browserIsSupported = computed(() => supportedNativeExtensions.value.length > 0)
const osIsSupported = computed(() => supportedBrowsers.value.length > 0)

const couldBeKiwi = (userBrowserName, suggestedBrowserName) =>
  userBrowserName === 'Chrome' && suggestedBrowserName === 'Kiwi'

const couldBeLemur = (userBrowserName, suggestedBrowserName) =>
  userBrowserName === 'Chrome' && suggestedBrowserName === 'Lemur'

const assignBrowser = (browserName, save = true) => {
  if (browserName === null || browserName === browser.name) return
  browser.name = browserName
  supportedNativeExtensions.value = getSupportedNativeExtensions(os.name, browserName)
  agentNotes.value = getBrowserNotes(os.name, browserName)
  if (save) saveBrowser(browserName)
  generateInstallationSteps()
}

const unassignBrowser = () => {
  deleteBrowser()
  const { browser: detectedBrowser } = UAParser()
  assignBrowser(detectedBrowser.name, false)
}

const generateInstallationSteps = () => {
  const extension = supportedNativeExtensions.value[0]
  if (!extension) return // No supported extensions means no installation steps
  installationSteps.value = [
    {
      title: t('installGuide.steps.install.title'),
      description: t('installGuide.steps.install.description', {
        browser: browser.name,
        os: os.name,
        store: extension.appStoreName
      }),
      action: {
        type: 'button',
        text: t('installGuide.steps.install.button', { browser: browser.name }),
        url: getLocalizedStoreUrl(extension, locale.value),
        taken: false
      }
    },
    {
      title: t('installGuide.steps.visitPi.title'),
      description: t('installGuide.steps.visitPi.description'),
      note: {
        label: t('installGuide.steps.visitPi.note.label'),
        text: t('installGuide.steps.visitPi.note.text')
      },
      action: {
        type: 'button',
        text: t('installGuide.steps.visitPi.button'),
        url: 'https://pi.ai/talk',
        taken: false
      }
    },
    {
      title: t('installGuide.steps.permissions.title'),
      description: t('installGuide.steps.permissions.description'),
      action: {
        type: 'image',
        src: '/images/chrome-use-microphones-tight-crop.png',
        alt: t('installGuide.steps.permissions.imageAlt'),
        taken: false
      }
    },
    {
      title: t('installGuide.steps.startSpeaking.title'),
      description: t('installGuide.steps.startSpeaking.description')
    }
  ]
}

const installationSteps = ref([])

const nextStep = () => {
  if (currentStep.value < installationSteps.value.length - 1) {
    currentStep.value++
    // Scroll to the next step
    const nextStepElement = document.getElementById(`step-${currentStep.value + 1}`)
    if (nextStepElement) {
      // wait for the next step to be rendered, then scroll to it
      setTimeout(() => {
        nextStepElement.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    }
  }
}

const markStepAsCompleted = () => {
  installationSteps.value[currentStep.value].action.taken = true
}

onMounted(() => {
  supportedNativeExtensions.value = getSupportedNativeExtensions(os.name, browser.name)
  supportedBrowsers.value = getSupportedBrowsers(os.name)
  agentNotes.value = getBrowserNotes(os.name, browser.name)

  if (hasSavedBrowser()) {
    const savedBrowser = loadBrowser()
    if (savedBrowser !== null && savedBrowser !== browser.name) {
      assignBrowser(savedBrowser)
    }
  }

  generateInstallationSteps()
})

// Add a watch for locale changes
watch(locale, ()=> {
  generateInstallationSteps()
})
</script>

<style scoped>
.top-level-component {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.main-heading {
  font-size: 2.5rem;
  color: #418a2f;
  margin-bottom: 2.5rem;
  text-align: center;
  font-weight: 600;
}

.installation-steps > div {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.step {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

h2 {
  font-size: 1.25rem;
  color: #418a2f;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.step-number {
  background-color: #776d6d;
  color: #ffffff;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  margin-right: 0.75rem;
  font-size: 0.9rem;
}

[dir="rtl"] .step-number {
  margin-right: 0;
  margin-left: 0.75rem;
}

p {
  color: #333333;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.action-button {
  background-color: #1e971e;
  color: #ffffff;
  border: none;
  padding: 1.25rem 2.5rem;
  font-size: 1.25rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

/* Add more bottom margin for RTL languages due to typically longer text */
[dir="rtl"] .action-button {
  margin-bottom: 2rem;
}

.action-button:hover {
  background-color: #147514;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.action-image {
  height: 180px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.note {
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.inline-icon {
  height: 1em;
  vertical-align: middle;
  margin: 0 0.25em;
}

.completed-indicator {
  color: #418a2f;
  margin-left: 0.5rem;
}

[dir="rtl"] .completed-indicator {
  margin-left: 0;
  margin-right: 0.5rem;
}

.navigation-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

[dir="rtl"] .navigation-container {
  right: auto;
  left: 1rem;
}

.step:has(a.action-button:not(.visited)) .next-button {
  background-color: grey;
}

.next-button {
  background-color: #418a2f;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:hover {
  background-color: #2c6420;
}

/* install buttons */
button:hover,
button:focus,
.install-link:hover,
.install-link:focus,
.assign-browser:hover,
.assign-browser:focus {
  transition: box-shadow 0.2s;
  box-shadow:
    0 8px 16px #0003,
    0 6px 20px #00000030;
}

button,
.install-link,
.install-link:visited,
.install-link.visited,
.install-link:active,
.assign-browser {
  transition: box-shadow 0.2s;
  display: inline-block;
  padding: 0.5em 1em;
  color: #fff;
  text-decoration: none;
  margin: 0 0.5em;
}

.extension .install-link {
  background-color: #0078d4;
}

.browser .install-link {
  background-color: #00a173;
}

.install-link.userscript,
.install-link.extension.native.stable {
  background-color: #1e971e;
  margin: 0.5em;
}

.browser.kiwi .assign-browser {
  background-color: #7bb661;
}

.browser.lemur .assign-browser {
  background-color: #00c9fe;
}

span.item-name {
  padding: 0.5em;
}

ul.supported-items {
  list-style: none;
  padding: 0;
}

ul.supported-items li {
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow:
    0 2px 4px #0003,
    0 1px 5px #00000030;
}

span.product-name {
  font-style: italic;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .top-level-component {
    padding: 1rem;
  }

  .main-heading {
    font-size: 27px;
  }

  h2 {
    font-size: 18px;
  }
  h2 > span.step-number {
    font-size: 16px;
    padding: 0.25rem 0.75rem;
    margin-right: 8px;
  }
  .action-button {
    font-size: unset;
  }

  .navigation-container {
    position: static;
    margin-top: 1rem;
    text-align: right;
  }

  [dir="rtl"] .navigation-container {
    text-align: left;
  }

  .next-button {
    padding: 1rem 1.5rem;
    font-size: 10px;
  }
  .note {
    display: none; /* hide notes on mobile - too small to be useful */
  }
}
</style>
