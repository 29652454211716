import { createRouter, createWebHistory } from 'vue-router'
import { h } from 'vue'
import { RouterView } from 'vue-router'
import Home from '../views/HomePage.vue'
import SupportPage from '../views/SupportSection.vue'
import i18n from '../i18n'

// Supported locales
const supportedLocales = ['en', 'fa', 'zh', 'es', 'fr', 'pt-BR', 'ru', 'de', 'it', 'ar', 'tr', 'uk', 'hi', 'ta', 'id', 'ms', 'ja', 'vi', 'ko', 'nl', 'pl']

// RTL languages
const rtlLocales = ['ar', 'fa']

// Create a proper component instead of inline template
const LocaleLayout = {
  render() {
    return h(RouterView)
  }
}

// Create routes with optional locale parameter
const routes = [
  {
    path: '/:locale?/', // Optional locale parameter
    component: LocaleLayout,
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      if (locale && !supportedLocales.includes(locale)) {
        return next('/en/')
      }
      next()
    },
    children: [
      {
        path: '', // Home route
        name: 'Home',
        component: Home,
        meta: { locale: 'en' }
      },
      {
        path: 'support', // Support route
        name: 'Support',
        component: SupportPage,
        meta: { locale: to => to.params.locale || 'en' }
      }
    ]
  },
  // Redirect any unknown routes to home
  {
    path: '/:pathMatch(.*)*',
    redirect: to => {
      // Try to detect user's preferred language
      const browserLocale = navigator.language || navigator.userLanguage
      const baseLocale = browserLocale.split('-')[0].toLowerCase()
      
      // First try to match the full locale code (e.g., 'pt-BR')
      const fullLocaleMatch = supportedLocales.find(locale => 
        locale.toLowerCase() === browserLocale.toLowerCase()
      )
      
      // If no full match, try to match just the language code (e.g., 'pt' from 'pt-BR')
      const baseLocaleMatch = supportedLocales.find(locale => 
        locale.toLowerCase().startsWith(baseLocale)
      )
      
      const preferredLocale = fullLocaleMatch || baseLocaleMatch || 'en'
      return `/${preferredLocale}${to.path}`
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    return { top: 0 };
  }
})

// Navigation guard to handle locale changes
router.beforeEach((to, from, next) => {
  const locale = to.params.locale || 'en'
  if (!supportedLocales.includes(locale)) {
    return next('/en/')
  }
  // Set i18n locale
  i18n.global.locale.value = locale
  // Update HTML lang attribute
  document.querySelector('html').setAttribute('lang', locale)
  // Update HTML dir attribute for RTL support
  document.querySelector('html').setAttribute('dir', rtlLocales.includes(locale) ? 'rtl' : 'ltr')
  next()
})

export default router
