import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import de from './locales/de.json';
import ptBR from './locales/pt-BR.json';
import zh from './locales/zh.json';
import fa from './locales/fa.json';
import ru from './locales/ru.json';
import it from './locales/it.json';
import ar from './locales/ar.json';
import tr from './locales/tr.json';
import uk from './locales/uk.json';
import hi from './locales/hi.json';
import ta from './locales/ta.json';
import id from './locales/id.json';
import ms from './locales/ms.json';
import ja from './locales/ja.json';
import vi from './locales/vi.json';
import ko from './locales/ko.json';
import nl from './locales/nl.json';
import pl from './locales/pl.json';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    fa,
    zh,
    es,
    fr,
    'pt-BR': ptBR,
    ru,
    de,
    it,
    ar,
    tr,
    uk,
    hi,
    ta,
    id,
    ms,
    ja,
    vi,
    ko,
    nl,
    pl
  },
  datetimeFormats: {
    'en': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'fa': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        calendar: 'persian'
      }
    },
    'zh': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'es': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'fr': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'pt-BR': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ru': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'de': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'it': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ar': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        calendar: 'islamic'
      }
    },
    'tr': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'uk': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'hi': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ta': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'id': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ms': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ja': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'vi': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'ko': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'nl': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'pl': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    }
  },
  numberFormats: {
    'en': {
      currency: {
        style: 'currency',
        currency: 'USD'
      }
    },
    'fa': {
      currency: {
        style: 'currency',
        currency: 'IRR',
        notation: 'standard'
      }
    },
    'zh': {
      currency: {
        style: 'currency',
        currency: 'CNY'
      }
    },
    'es': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'fr': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL'
      }
    },
    'ru': {
      currency: {
        style: 'currency',
        currency: 'RUB'
      }
    },
    'de': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'it': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'ar': {
      currency: {
        style: 'currency',
        currency: 'AED'
      }
    },
    'tr': {
      currency: {
        style: 'currency',
        currency: 'TRY'
      }
    },
    'uk': {
      currency: {
        style: 'currency',
        currency: 'UAH'
      }
    },
    'hi': {
      currency: {
        style: 'currency',
        currency: 'INR'
      }
    },
    'ta': {
      currency: {
        style: 'currency',
        currency: 'INR'
      }
    },
    'id': {
      currency: {
        style: 'currency',
        currency: 'IDR'
      }
    },
    'ms': {
      currency: {
        style: 'currency',
        currency: 'MYR'
      }
    },
    'ja': {
      currency: {
        style: 'currency',
        currency: 'JPY'
      }
    },
    'vi': {
      currency: {
        style: 'currency',
        currency: 'VND'
      }
    },
    'ko': {
      currency: {
        style: 'currency',
        currency: 'KRW'
      }
    },
    'nl': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'pl': {
      currency: {
        style: 'currency',
        currency: 'PLN'
      }
    }
  }
});

export const setLocale = async (locale) => {
  i18n.global.locale.value = locale;
  document.querySelector('html').setAttribute('lang', locale);
  // Set RTL/LTR direction
  const rtlLocales = ['ar', 'fa'];
  document.querySelector('html').setAttribute('dir', rtlLocales.includes(locale) ? 'rtl' : 'ltr');
};

// Initialize with English locale
setLocale('en');

export default i18n; 