<template>
  <section class="review-section">
    <div class="top-level-component">
      <h2>{{ $t('reviews.title') }}</h2>
      <div class="carousel-container">
        <button class="nav-button prev" @click="prevSlide" :aria-label="$t('reviews.navigation.previous')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M15 18l-6-6 6-6" />
          </svg>
        </button>
        
        <div class="review-card text-copy">
          <div class="stars">
            ★★★★★
          </div>
          <p class="review-content">{{ currentReview.content }}</p>
          <p class="reviewer">{{ $t('reviews.attribution', { username: currentReview.username }) }}</p>
        </div>

        <button class="nav-button next" @click="nextSlide" :aria-label="$t('reviews.navigation.next')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M9 18l6-6-6-6" />
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import reviews from '@/configs/chrome-reviews.json'

// Filter for 5-star reviews
const fiveStarReviews = computed(() => 
  reviews.reviews.filter(review => review.rating === 5)
)

const currentIndex = ref(0)

const currentReview = computed(() => 
  fiveStarReviews.value[currentIndex.value]
)

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % fiveStarReviews.value.length
}

const prevSlide = () => {
  currentIndex.value = currentIndex.value === 0 
    ? fiveStarReviews.value.length - 1 
    : currentIndex.value - 1
}

// Auto-advance carousel
onMounted(() => {
  const timer = setInterval(nextSlide, 10000)
  return () => clearInterval(timer)
})
</script>

<style scoped>
.review-section {
  padding: 4rem 0;
}

.review-section .top-level-component {
  display: block;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

h2 {
  text-align: center;
  color: #418a2f;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.review-card {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stars {
  color: #f0b528;
  font-size: large;
  margin-bottom: 1rem;
}

.review-content {
  color: #776d6d;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.reviewer {
  color: #418a2f;
  font-weight: 500;
}

.nav-button {
  background: none;
  border: none;
  color: #418a2f;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.2s;
}

.nav-button:hover {
  transform: scale(1.2);
}

.nav-button svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .review-section {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  .carousel-container {
    gap: 1rem;
  }

  .review-card {
    padding: 1.5rem;
    min-height: 150px;
  }

  .review-content {
    line-height: 1.4;
  }

  .reviewer {
    font-size: small;
  }
}
</style> 