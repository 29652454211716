<script setup>
import './shared.css' // Import shared styles
import { onMounted } from 'vue'

onMounted(() => {
  import('https://elevenlabs.io/convai-widget/index.js')
})
</script>

<template>
  <section id="claude" class="feature-announcement top-level-component">
    <div class="content">
      <div class="image-content eye-candy">
        <img src="/images/alter.png" :alt="$t('teaser.image.alt')" />
      </div>
      <div class="text-copy">
        <div>
          <h1>{{ $t('teaser.title') }}</h1>
          <p>{{ $t('teaser.description.main') }}</p>
          <p>
            <a
              href="https://youtu.be/YeaRQOPn6YA?si=jAuIJWJ72ZpaO_Uu"
              target="_blank"
              class="cta-link youtube-link"
            >{{ $t('teaser.links.meet') }}</a>, 
            <a
              href="https://youtu.be/s2ZgaBRSrk0?si=dBVyd9fV9rkVtuAZ"
              target="_blank"
              class="cta-link youtube-link"
            >{{ $t('teaser.links.languages') }}</a>.
          </p>
          <p id="talk-to-alter">
            <elevenlabs-convai agent-id="vDSzDds2d0UQdVIfru38"></elevenlabs-convai>
          </p>
          <p>{{ $t('teaser.description.pioneer') }}</p>
          <div class="launchlist-widget" data-key-id="zpFDp8" data-height="115px"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {
  background-color: #e4d8c1;
  overflow: hidden;
}

.content {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.text-copy {
  padding: 10px 5%;
  flex: 1;

  background-image: url('/images/elevenlabs-grants-logo.png');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-bottom: 4rem;
}

.image-content {
  flex: 1;
  max-width: 50%;
}

.image-content img {
  width: 100%;
  height: auto;
  max-width: 634px;
}

h1 {
  font-size: 2.5em;
  margin: 0 0 0.5em 0;
  color: #333;
}

h2 {
  font-size: 1.8em;
  line-height: 1.4em;
  text-transform: capitalize;
  color: #555;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #007bff;
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-link {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .image-content {
    max-width: 100%;
    order: -1;
  }

  .text-copy {
    font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.34944363em - var(--ffsd)));
  }

  .launchlist-widget {
    margin-bottom: 1em;
  }
}

#talk-to-alter {
  padding: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
}
#talk-to-alter elevenlabs-convai {
  position: initial;
  margin: 0 auto;
}
</style>
