<template>
  <section class="canva-videos top-level-component">
    <div class="sections-container">
      <div class="mobile-section">
        <div class="section-content">
          <div class="text-content text-copy">
            <h2>{{ $t('canvaVideos.mobile.title') }}</h2>
            <p>{{ $t('canvaVideos.mobile.description1') }}</p>
            <p>{{ $t('canvaVideos.mobile.description2') }}</p>
            <p>{{ $t('canvaVideos.mobile.description3') }}</p>
            <p>{{ $t('canvaVideos.mobile.description4') }}</p>
            <a @click.prevent="$router.push({ hash: '#install-guide' })" href="#" class="cta-button">{{ $t('canvaVideos.mobile.cta') }}</a>
          </div>
          <div class="device-demo mobile">
            <img
              src="/images/mobile-frame.svg"
              :alt="$t('canvaVideos.video.mobileFrame')"
              class="device-frame mobile"
            />
            <video
              ref="mobileVideo"
              :src="getVideoSource('mobile')"
              class="device-video mobile"
              loop
              playsinline
              @play="updatePlayState('mobileVideo', true)"
              @pause="updatePlayState('mobileVideo', false)"
            ></video>
            <div class="video-overlay" @click="togglePlay('mobileVideo')">
              <img
                v-if="!isMobileVideoPlaying"
                src="/images/play.svg"
                :alt="$t('canvaVideos.video.play')"
                class="control-icon"
              />
              <img 
                v-else 
                src="/images/pause.svg" 
                :alt="$t('canvaVideos.video.pause')" 
                class="control-icon" 
              />
            </div>
          </div>
        </div>
      </div>
      <div class="desktop-section">
        <h2>{{ $t('canvaVideos.desktop.title') }}</h2>
        <div class="section-content text-copy">
          <div class="text-content">
            <p>{{ $t('canvaVideos.desktop.description1') }}</p>
            <p>{{ $t('canvaVideos.desktop.description2') }}</p>
            <a @click.prevent="$router.push({ hash: '#install-guide' })" href="#" class="cta-button">{{ $t('canvaVideos.desktop.cta') }}</a>
          </div>
          <div class="device-demo desktop">
            <img 
              src="/images/macbook-frame.png" 
              :alt="$t('canvaVideos.video.macFrame')" 
              class="device-frame desktop" 
            />
            <video
              ref="desktopVideo"
              :src="getVideoSource('desktop')"
              class="device-video desktop"
              loop
              playsinline
              @play="updatePlayState('desktopVideo', true)"
              @pause="updatePlayState('desktopVideo', false)"
            ></video>
            <div class="video-overlay" @click="togglePlay('desktopVideo')">
              <img
                v-if="!isDesktopVideoPlaying"
                src="/images/play.svg"
                :alt="$t('canvaVideos.video.play')"
                class="control-icon"
              />
              <img 
                v-else 
                src="/images/pause.svg" 
                :alt="$t('canvaVideos.video.pause')" 
                class="control-icon" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import './shared.css'

const { locale } = useI18n()

const mobileVideo = ref(null)
const desktopVideo = ref(null)
const isMobileVideoPlaying = ref(false)
const isDesktopVideoPlaying = ref(false)

// Video sources by locale
const videoSources = {
  mobile: {
    en: '/videos/saypi-mobile-demo-v163.mp4',
    // es: '/videos/saypi-mobile-demo-es-v163.mp4',
    // Add other locales as they become available
    // fr: '/videos/saypi-mobile-demo-fr-v163.mp4',
    // de: '/videos/saypi-mobile-demo-de-v163.mp4',
    // 'pt-BR': '/videos/saypi-mobile-demo-pt-br-v163.mp4'
  },
  desktop: {
    en: '/videos/saypi-desktop-demo-v163.mp4',
    // es: '/videos/saypi-desktop-demo-es-v163.mp4',
    // Add other locales as they become available
    // fr: '/videos/saypi-desktop-demo-fr-v163.mp4',
    // de: '/videos/saypi-desktop-demo-de-v163.mp4',
    // 'pt-BR': '/videos/saypi-desktop-demo-pt-br-v163.mp4'
  }
}

// Get video source with fallback to English
const getVideoSource = (type) => {
  return videoSources[type][locale.value] || videoSources[type]['en']
}

const togglePlay = (videoRef) => {
  const video = videoRef === 'mobileVideo' ? mobileVideo.value : desktopVideo.value
  if (video.paused) {
    video.play()
  } else {
    video.pause()
  }
}

const updatePlayState = (videoRef, isPlaying) => {
  if (videoRef === 'mobileVideo') {
    isMobileVideoPlaying.value = isPlaying
  } else {
    isDesktopVideoPlaying.value = isPlaying
  }
}
</script>

<style scoped>
.canva-videos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sections-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.mobile-section,
.desktop-section {
  width: 100%;
  margin-bottom: 4rem;
}

.section-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

h2 {
  font-size: 2em;
  color: #418a2f;
  margin-bottom: 1rem;
}

h3 {
  color: #776d6d;
  margin-bottom: 1rem;
}

p {
  color: #776d6d;
  margin-bottom: 1rem;
}

.text-content {
  flex: 1;
  padding-right: 3rem;
}

.cta-button {
  display: inline-block;
  background-color: #cfcbc0;
  color: #776d6d;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
}

.device-demo {
  position: relative;
  flex-shrink: 0;
}

.device-demo.mobile {
  height: 400px;
  width: 200px;
  margin-right: 50px; /* to align with desktop video below */
}

.device-frame {
  height: 400px;
  z-index: 100;
  position: relative;
}

.device-frame.desktop {
  height: auto;
  width: 400px; /* Adjust this value to fit your layout */
}

.device-video {
  z-index: 1;
  position: absolute;
}

video.device-video.mobile {
  top: 10px;
  left: 10px;
  height: 380px;
}

video.device-video.desktop {
  top: 5.5%;
  left: 11.8%;
  width: 76.4%;
  height: 82%;
}

@media (max-width: 768px) {
  .section-content {
    flex-direction: column-reverse;
  }

  .text-content {
    padding-right: 0;
    margin-top: 2rem;
  }

  .device-demo {
    margin: 0 auto;
  }

  .desktop-section {
    display: none;
  }
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent overlay */
}

.control-icon {
  width: 60px;
  height: 60px;
  z-index: 102;
}

.video-overlay:hover {
  opacity: 1;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
</style>
