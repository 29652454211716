<template>
    <div class="status-container">
        <div class="status" :class="status.status_code">
            <p v-if="status.status_code === 'normal'" class="message normal">{{ status.message }}</p>

            <p v-else-if="status.status_code === 'unknown'" class="message unknown">{{ status.message }}</p>

            <div v-else>
                <h2 :class="status.severity">System Issue Alert</h2>
                <p class="message">{{ status.message }}</p>
                <p v-if="status.details" class="details">{{ status.details }}</p>
                <p v-if="status.recommended_actions" class="recommended-actions">
                    <strong>In the meantime, we recommend:</strong>
                </p>
                <ul>
                    <li v-for="action in status.recommended_actions" :key="action.action">
                        {{ action.description }}
                    </li>
                </ul>
                <p v-if="status.version" class="version">
                    {{ status.version.message }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
const unknown = {
    status_code: "unknown",
    message: "The status of the system is currently unknown.",
}

export default {
    name: 'SystemStatus',
    data() {
        return {
            status: unknown
        };
    },
    created() {
        this.getStatus();
    },
    methods: {
        async getStatus() {
            try {
                const response = await fetch('https://api.saypi.ai/status');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const status = await response.json();
                this.status = status;
            } catch (e) {
                console.error('An error occurred while fetching the status:', e);
                this.status = unknown;
            }
        }
    },
    computed: {
        // Your computed properties go here
    },
};
</script>

<style scoped>
.status {
    font-size: 1.5rem;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
}

h2,
p.normal,
p.unknown {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 2rem;
    border-radius: 0.5rem;

}

p.normal {
    font-size: 1.5rem;
    background-color: green;
    color: white;
}

p.unknown {
    font-size: 1.5rem;
    background-color: grey;
    color: white;
}

h2.major {
    background-color: orange;
    color: white;
}

h2.minor {
    background-color: yellow;
    color: black;
}

h2.critical {
    background-color: red;
    color: white;
}

ul {
    list-style-type: none;
    /* Remove default bullets */
    padding-left: 3.5rem;
    font-size: 1.25rem;
}

ul li::before {
    content: "🥧";
    /* Add pie emoji before each list item */
    margin-right: 10px;
    /* Add some space between the emoji and the list item text */
}

p {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
}

p.version {
    font-size: 1.25rem;
}
</style>